/* Footer */

	#footer {
		.copyright {
			text-align: center;
			opacity: 0.35;
			font-size: 0.8em;
			margin: (_size(element-margin) * 2) 0 0 0;

			&:before {
				content: '';
				display: block;
				width: 4rem;
				height: 1px;
				margin: 0 auto 2rem auto;
				border-top: solid 1px;
				opacity: 0.5;
			}
		}
	}

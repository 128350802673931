section#footer section {
  width: 100%;
  text-align: center;

  h3:before {
    width: 2.5em;
    margin-left: auto;
    margin-right: auto;
  }
}

#intro .image {
  border: none;
  border-radius: 100%;
  background-color: #f5f5f5;
}

#intro header img {
  @include breakpoint(xlarge) {
    width: 500px;
  }

  @include breakpoint(large) {
    width: 400px;
  }

  @include breakpoint(medium) {
    width: 400px;
  }

  @include breakpoint(small) {
    width: 300px;
  }

  @include breakpoint(xsmall) {
    width: 300px;
  }

  @include breakpoint(xxsmall) {
    width: 270px;
  }
}

.center {
  text-align: center;
}

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;

		&:after {
			content: '';
			display: block;
			clear: both;
			height: 1px;
		}

		.field {
			position: relative;
			float: left;
			margin: 0 0 (_size(gutter) * 0.5) 0;
			vertical-align: top;
			width: 100%;
		}

		.half {
			width: calc(50% + #{_size(gutter) * 0.25}) !important;
			padding-left: _size(gutter) * 0.5;
		}

		.half.first {
			width: calc(50% - #{_size(gutter) * 0.25}) !important;
			padding-left: 0;
		}

		:last-child {
			margin-bottom: 0;
		}

		.half:nth-last-child(2) {
			margin-bottom: 0;
		}

		.actions {
			position: relative;
			clear: both;
			margin-bottom: 0 !important;
		}

		@include breakpoint(xsmall) {
			.half {
				width: 100% !important;
				padding-left: 0;
			}

			.half.first {
				width: 100% !important;
				padding-left: 0;
			}

			.half:nth-last-child(2) {
				margin: 0 0 (_size(element-margin) * 0.65) 0;
			}
		}
	}

	label {
		color: inherit;
		display: block;
		font-size: 0.9em;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		border-radius: _size(border-radius);
		border: none;
		border: solid 1px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			@include icon;
			color: inherit;
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				border-radius: _size(border-radius);
				border: solid 1px;
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				content: '\f00c';
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: inherit !important;
		opacity: 0.5;
	}

	:-moz-placeholder {
		color: inherit !important;
		opacity: 0.5;
	}

	::-moz-placeholder {
		color: inherit !important;
		opacity: 0.5;
	}

	:-ms-input-placeholder {
		color: inherit !important;
		opacity: 0.5;
	}

	@mixin color-form($p: null) {
		.select-wrapper {
			&:before {
				color: _palette($p, border-alt);
			}
		}

		@if ($p == 'invert') {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			select,
			textarea {
				background: _palette($p, border-bg);
				border-color: inherit;

				&:focus {
					background: _palette($p, border-alt);
					box-shadow: 0 0 0 1px _palette($p, border);
				}
			}

			input[type="checkbox"],
			input[type="radio"], {
				& + label {
					&:before {
						background: _palette($p, border-bg);
						border-color: inherit;
					}
				}

				&:focus + label {
					&:before {
						background: _palette($p, border-bg);
						box-shadow: 0 0 0 1px _palette($p, border);
					}
				}

				&:checked + label {
					&:before {
						background-color: _palette($p, fg);
						border-color: _palette($p, fg);
						color: _palette($p, bg);
					}
				}
			}
		}
		@else {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			select,
			textarea {
				background: _palette($p, bg);
				border-color: _palette($p, border-alt);

				&:focus {
					border-color: _palette($p, border);
					box-shadow: 0 0 0 1px _palette($p, border);
				}
			}

			input[type="checkbox"],
			input[type="radio"], {
				& + label {
					&:before {
						background: _palette($p, bg);
						border-color: _palette($p, border-alt);
					}
				}

				&:focus + label {
					&:before {
						box-shadow: 0 0 0 1px _palette($p, border);
						border-color: _palette($p, border);
					}
				}

				&:checked + label {
					&:before {
						background-color: _palette($p, fg);
						border-color: _palette($p, fg);
						color: _palette($p, bg);
					}
				}
			}
		}
	}

	@include color-form;

/* Type */

	body {
		background-color: _palette(bg);
		color: _palette(fg);
	}

	body, input, select, textarea {
		font-family: _font(family);
		font-size: 14pt;
		font-weight: _font(weight);
		line-height: 1.75;
		letter-spacing: 0.025em;

		@include breakpoint(xlarge) {
			font-size: 12pt;
		}

		@include breakpoint(large) {
			font-size: 11pt;
		}

		@include breakpoint(medium) {
			font-size: 12pt;
		}

		@include breakpoint(small) {
			font-size: 11pt;
		}

		@include breakpoint(xsmall) {
			font-size: 11pt;
		}

		@include breakpoint(xxsmall) {
			font-size: 10.5pt;
		}
	}

	input, select, textarea {
		color: inherit;
	}

	a {
		@include vendor('transition', 'border-bottom-color #{_duration(transition)} ease');
		color: inherit;
		text-decoration: none;
		border-bottom: dotted 1px;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		color: inherit;
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: inherit;
		font-weight: _font(weight);
		line-height: 1.5;
		margin: 0 0 1.75rem 0;
		letter-spacing: 0.05em;

		a {
			color: inherit;
			text-decoration: none;
		}

		@include breakpoint(large) {
			margin: 0 0 1.5rem 0;
		}

		@include breakpoint(small) {
			margin: 0 0 1.25rem 0;
		}
	}

	h1 {
		font-size: 3.25em;
		line-height: 1.1;
		margin: 0 0 1.5rem 0;
		letter-spacing: 0.025em;

		@include breakpoint(small) {
			font-size: 2em;
			line-height: 1.3;
			margin: 0 0 1rem 0;
		}
	}

	h2 {
		font-size: 2.5em;
		line-height: 1.3;

		@include breakpoint(large) {
			font-size: 2.25em;
		}

		@include breakpoint(small) {
			font-size: 1.5em;
			line-height: 1.5;
		}
	}

	h3 {
		font-size: 1.75em;

		@include breakpoint(small) {
			font-size: 1.25em;
		}
	}

	h4 {
		font-size: 1.1em;

		@include breakpoint(small) {
			font-size: 1em;
		}
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px;
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: calc(_size(element-margin) / 4) 0 calc(_size(element-margin) / 4) _size(element-margin);
	}

	code {
		border-radius: _size(border-radius);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 1px;
		margin: (_size(element-margin) * 1.5) 0;

		&.major {
			margin: (_size(element-margin) * 2.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@mixin color-typography($p: null) {
		@if $p != null {
			background-color: _palette($p, bg);
			color: _palette($p, fg);
		}

		blockquote {
			border-left-color: _palette($p, border-alt);
		}

		hr {
			border-bottom-color: _palette($p, border-alt);
		}

		code {
			background: _palette($p, border-bg);
		}
	}

	@include color-typography;

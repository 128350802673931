/* Header */

	#header {
		position: fixed;
		right: 2em;
		top: 2em;
		z-index: _misc(z-index-base);

		ul {
			@include vendor('display', 'flex');
			@include vendor('align-items', 'center');
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: block;
				padding: 0;

				a {
					display: block;
					position: relative;
					height: 3.75em;
					line-height: 3.75em;
					padding: 0 2em 0 0;
					border: 0;
				}

				a[href="#menu"] {
					$size: 0.8;

					@include vendor('transition', (
						'width #{_duration(transition)} ease',
						'border-radius #{_duration(transition)} ease',
						'color #{_duration(transition)} ease'
					));
					-webkit-tap-highlight-color: transparent;
					background-color: transparentize(darken(_palette(fg), 5), 0.1);
					border-radius: 100%;
					color: _palette(fg);
					overflow: hidden;
					padding: 0;
					text-decoration: none;
					white-space: nowrap;
					font-size: (1em * $size);
					text-transform: uppercase;
					letter-spacing: 0.2em;
					text-indent: calc(-3.75em / $size);
					width: calc(3.75em / $size);
					height: calc(3.75em / $size);
					line-height: calc(3.75em / $size);

					&:before {
						@include vendor('transition', 'all #{_duration(transition)} ease');
						content: '';
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" preserveAspectRatio="none"><style>line { stroke-width: 6px; stroke: #{_palette(bg)}; }</style><line x1="10" y1="30" x2="90" y2="30" /><line x1="10" y1="50" x2="90" y2="50" /><line x1="10" y1="70" x2="90" y2="70" /></svg>');
						background-position: center;
						background-repeat: no-repeat;
						width: calc(3.75em / $size);
						height: calc(3.75em / $size);
						background-size: calc(2em / $size);
					}

					&:hover {
						color: _palette(bg);
						text-indent: 0;
						width: calc(7.75em / $size);
						border-radius: calc(3.75em / $size);
						padding-left: calc(1.5em / $size);

						&:before {
							background-size: calc(1.25em / $size);
						}
					}
				}
			}
		}

		@include breakpoint(small) {
			right: 1em;
			top: 1em;

			ul {
				li {
					a {
						height: 3em;
						line-height: 3em;
						padding: 0 1em 0 0;
					}

					a[href="#menu"] {
						$size: 0.8;

						text-indent: calc(-3em / $size);
						width: calc(3em / $size);
						height: calc(3em / $size);
						line-height: calc(3em / $size);

						&:before {
							width: calc(3em / $size);
							height: calc(3em / $size);
							background-size: calc(1.75em / $size);
						}

						&:hover {
							border-radius: 100%;
							padding-left: 0;
							text-indent: calc(-3em / $size);
							width: calc(3em / $size);

							&:before {
								background-size: calc(1.75em / $size);
							}
						}
					}
				}
			}
		}
	}

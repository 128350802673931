/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/raleway/raleway-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light'), local('Raleway-Light'),
       url('fonts/raleway/raleway-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/raleway/raleway-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/raleway/raleway-v12-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/raleway/raleway-v12-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
       url('fonts/raleway/raleway-v12-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/raleway/raleway-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/raleway/raleway-v12-latin-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/raleway/raleway-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium'), local('Raleway-Medium'),
       url('fonts/raleway/raleway-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/raleway/raleway-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/raleway/raleway-v12-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-500italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/raleway/raleway-v12-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
       url('fonts/raleway/raleway-v12-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/raleway/raleway-v12-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/raleway/raleway-v12-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/raleway/raleway-v12-latin-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
